export const BIG_DATA_HEADING = [
  'Big Data Analytics'
];
export const BIG_DATA_SUB_HEADING = ['Our Big Data expertise encompasses dozens of successful projects, in-depth knowledge of popular open-source and proprietary tools, and a large team of experts who can deliver Big Data solutions of any complexity and scale'];
export const BIG_DATA_SECOND_BLOCK_HEADING = 'Expert Big Data Services';
export const BIG_DATA_SECOND_BLOCK_TEXT =
  'Specializing in all types of Big Data solutions, we’ve gathered an outstanding team of experts who can get your project moving in the right direction from day one. We can assist you in clarifying requirements and polishing your go-to-market strategy as well as in building the solution that fulfills your business objectives.';
export const BIG_DATA_SECOND_BLOCK_CONTENT = [
  {
    icon: 'big-data-processing-warehousing',
    title: 'Big Data processing & warehousing',
    text:
      'Implement Big Data tools and techniques that help process vast volumes of data in real time as well as extract better insights from data at-rest. Design and set up data warehousing infrastructure to support the unique structure and lifecycle of your business data.',
  },
  {
    icon: 'custom-product-development',
    title: 'Custom product development',
    text:
      'Leverage our full-stack software development and Big Data expertise to build a custom analytics solution for your business and your clients. Whether it’s a technology platform, an enterprise analytics solution, or a Big Data integration use case, we can deliver.',
  },
  {
    icon: 'big-data-bi-consulting',
    title: 'Big Data & BI consulting',
    text:
      'Kickstart your Big Data project with the expert choice of necessary tools, infrastructure, and the project delivery plan. Design a modern, reliable, and efficient architecture for your Big Data solution or adapt existing solutions to fit your business needs.'
  },
  {
    icon: 'data-integration-engineering',
    title: 'Data integration & engineering',
    text:
      'Consolidate data from multiple sources to have a unified view of your business metrics, operational, and customer data. Set up effective ETL pipelines and take advantage of an automated data ingestion process and data synchronization across multiple data stores.'
  },
  {
    icon: 'hadoop-services',
    title: 'Hadoop services',
    text:
      'Modernize your Hadoop-based solution with new features or customized components, automateQA processes, port features from/to open-source repositories, integrate proprietary Hadoop-based solutions and services, and optimize performance of your Hadoop infrastructure.'
  },
  {
    icon: 'managed-services',
    title: 'Managed services',
    text:
      'Ensure reliable management of your Big Data infrastructure, including cluster deployment and performance management, day-to-day system and user administration, virtualization, CI/CD automation, and other DevOps activities for your Big Data ecosystem.'
  },
  {
    icon: 'data-science',
    title: 'Data science',
    text:
      'Gather maximum insights from the existing data sources and create new ones to support your business growth. Clean, analyze, and visualize your data using your favorite BI tools. Improve your existing data architecture to support the most valuable business decisions and business development activities.'
  },
  {
    icon: 'vendor-technology-migration',
    title: 'Vendor & technology migration',
    text:
      'Take advantage of our in-depth knowledge of modern Big Data technologies to create the best ecosystem of data analytics components for your business. Painlessly transfer data and applications between different cloud vendors and Big Data platforms.'
  },
  {
    icon: 'data-security',
    title: 'Data security',
    text:
      'Ensure security of your transactional data, user data, and data stores by implementing modern security mechanisms into your application stack. Set up security policies for every aspect of data handling, apply modern identity & access management tools, automate data backups, and meet your industry’s security compliance standards.'
  }
];

export const BIG_DATA_SUCCESS_STORIES_SUB_HEADING = [
  'From essential Hadoop components in the early days of Big Data to cutting-edge technologies that will be shaping the future of Data Analytics, we’ve accomplished dozens of projects that allowed our clients to grow their businesses.',
];

export const BIG_DATA_THIRD_BLOCK_HEADING = 'Full-stack Big Data Solutions';
export const BIG_DATA_THIRD_BLOCK_SUBHEADING =
  'CyberVision offers full-stack data analytics solutions and services, backed by our portfolio of successful projects that encompass each part of the Big Data lifecycle — from data collection to warehousing, data analysis, BI and reporting. We have in-depth expertise in modern open-source and commercial tools for Big Data, machine learning, and artificial intelligence. We also know how to apply those tools to resolve real-life problems and help companies succeed with their digital strategies.';
export const BIG_DATA_THIRD_BLOCK_CONTENT = [
  {
    icon: 'streaming-analytics',
    title: 'Streaming analytics',
    text: 'Real-time data analysis for a variety of business use cases, from system health monitoring and fraud detection to sensor and user data analytics.'
  },
  {
    icon: 'cloud-based-analytics-portals',
    title: 'Cloud-based analytics portals',
    text: 'Advanced cluster management and data science applications for Big Data professionals and companies offering commercial Big Data solutions'
  },
  {
    icon: 'business-intelligence',
    title: 'Business intelligence & reporting solutions',
    text: 'Modern business intelligence solutions powered by the latest technologies and capabilities that include predictive modeling automation, real-time and historical analytics, ML applications, etc.'
  },
  {
    icon: 'automated-data',
    title: 'Automated data analytics pipelines',
    text: 'Automated ETL / ELT data analytics pipelines with end-to-end system integration, from a data source to a data warehouse.'
  },
  {
    icon: 'iot-analytics',
    title: 'End-to-end IoT analytics',
    text: 'Edge to cloud IoT data analytics, including real-time and historical device data analytics, on-device analytics, smart alerts, predictive maintenance, A/B testing, etc.'
  },
  {
    icon: 'predictive-analytics',
    title: 'Predictive analytics',
    text: 'Enterprise analytics based on predictive modeling to address more accurate operational and strategic planning, detect early signs of equipment malfunctions, and ensure better tracking of business KPIs.'
  },
  {
    icon: 'cluster-management',
    title: 'Cluster management tools',
    text: 'Monitoring hundreds of nodes in a single or multi-cluster deployment via a user-friendly graphical interface, configuring cluster services and managing resources.'
  },
  {
    icon: 'data-scientists',
    title: 'Solutions for data scientists',
    text: 'Custom-tailored analytics and BI tools for data scientists featuring drag-n-drop user interface, industry-specific analytics modules, and third-party components integration.'
  }
];

export const BIG_DATA_FOURTH_BLOCK_HEADING = 'Partnerships';
export const BIG_DATA_FOURTH_BLOCK_SUBHEADING = [
  'Our partnerships in the Big Data world with key Hadoop distributors',
  'and Data Analytics companies:',
];
export const BIG_DATA_FOURTH_BLOCK_CONTENT = [
  {
    icon: 1,
  },
  {
    icon: 2,
  },
  {
    icon: 3,
  },
  {
    icon: 4,
  },
];

export const BIG_DATA_FIFTH_BLOCK_HEADING = 'Business Domains';
export const BIG_DATA_FIFTH_BLOCK_CONTENT = [
  {
    tabTitle: 'Enterprise',
    title: 'Big Data for enterprise',
    text: 'As a software development & consulting company with over two decades of experience in major industries, CyberVision helps traditional companies to leverage the power of Big Data in a variety of business use cases. Manufacturing, financial operations, telecommunications, automotive, or consumer electronics - in every line of business, companies leverage Big Data analytics to measure their business KPIs in real-time, understand customer behavior, optimize production and service operations, predict future trends, and create new streams of revenue based on data.'
  },
  {
    tabTitle: 'Tech vendors',
    title: 'Big Data for tech vendors',
    text: 'CyberVision has been a development partner for many companies building their unique Big Data platforms and frameworks, including commercial Hadoop distributions, solutions for data engineers and data scientists, cluster management frameworks, and other technology. We’ve also contributed to popular open-source Big Data solutions. This in-depth practical knowledge of time-honored Big Data platforms as well as cutting-edge technology makes our company a reliable full-stack technology partner for Big Data tech vendors, innovative start-ups, and R&D initiatives.'
  },
  {
    tabTitle: 'Telecom operators',
    title: 'Big Data for telecom operators',
    text: 'Telecom has been among our top industries, and Big Data is everywhere in Telecom. Combining our vast experience in O/BSS stack, network engineering, connectivity, and IoT technologies with Big Data, CyberVision has helped top tier Telecom companies to successfully adopt Big Data into their core operations. For example, as a Google Cloud system integration partner, CyberVision enables telecom companies to effectively implement Big Data solutions from the Google Cloud Platform’s ecosystem and seamlessly integrate them within operators’ existing application landscapes.'
  },
  {
    tabTitle: 'System Integrators',
    title: 'Big Data for system integrators',
    text: 'For system integrators, CyberVision offers a flexible delivery model based on joint project collaboration or a dedicated team of Big Data experts that augments the client’s team. By leveraging our vast skills and knowledge of Big Data technology, system integrators can engage in complex projects with minimal ramp-up time and enlarge the scope of their business opportunities. Acting as a one-stop shop for many of our clients, it is customary for CyberVision team to close multiple gaps in the client’s project staffing and see it through until the successful product deployment on production.'
  },
  {
    tabTitle: 'IoT companies',
    title: 'Big Data for IoT companies',
    text: 'Smart IoT devices generate vast amounts of data, which can be used to improve the user experience and create new revenue streams. Having in-depth expertise in both Big Data and IoT domains, CyberVision can assist IoT device vendors and IoT technology companies in making the most of their IoT data. We can deliver end-to-end IoT data processing solutions on top of your existing software and hardware stack, from edge analytics to real-time IoT dashboards and rule-based data analytics. We can also select the right cloud infrastructure and Big Data tools for your IoT business case.'
  }
];

export const BIG_DATA_SIXTH_BLOCK_HEADING = 'Technologies';
export const BIG_DATA_SIXTH_BLOCK_SUBHEADING = 'We have vast experience using popular open-source data analytics tools under the Apache foundation as well as commercial tools from Amazon, Google, and other tech vendors.';

export const BIG_DATA_SEVENTH_BLOCK_CONTENT = [
  {
    number: '01',
    title: 'Expertise',
    text: 'Broad expertise covering all key aspects of Big Data implementation'
  },
  {
    number: '02',
    title: 'People',
    text: 'Large team of Big Data experts, engineers, and data scientists'
  },
  {
    number: '03',
    title: 'Partnership',
    text: 'Long-standing partnerships with high-profile tech vendors in the Big Data industry'
  },
  {
    number: '04',
    title: 'Contributions',
    text: 'Solid track record of direct contributions to Hadoop ecosystem'
  },
  {
    number: '05',
    title: 'Experience',
    text: '10+ years of continuous implementation of the Big Data technologies with dozens of success stories'
  }
];

export const BIG_DATA_EIGHTH_BLOCK_HEADING = 'Insights';
