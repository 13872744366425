import Tab from '@material-ui/core/Tab';
import MaterialUITabs from '@material-ui/core/Tabs';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import styles from './Tabs.module.scss';

// TODO Complete styles for horizontal layout

const Tabs = ({className, content = [], defaultTab = 0, id, isVertical}) => {
  const [currentTab, setCurrentTab] = useState(defaultTab);
  const onChangeTab = (e, index) => setCurrentTab(index);

  const isActive = index => index === currentTab;
  const isAfterActive = index => index - 1 === currentTab;
  const isBeforeActive = index => index + 1 === currentTab;
  const isFirstTabSelected = () => currentTab === 0;
  const isLastTabSelected = () => currentTab === content.length - 1;

  const renderTabContent = tabContent => {
    if (typeof tabContent === 'function') {
      return tabContent();
    }
    if (typeof tabContent === 'string') {
      return tabContent;
    }
    if (tabContent) {
      return tabContent.toString();
    }
    return null;
  };

  return <div className={classNames(styles['tabs-container'], className, {[styles['vertical']]: isVertical})}>
    <MaterialUITabs
      classes={{
        flexContainer: styles['tabs-sidebar-container'],
        root: classNames(styles['tabs-sidebar-root'], {
          [styles['tabs-sidebar-root-with-first-selected']]: isFirstTabSelected(),
          [styles['tabs-sidebar-root-with-last-selected']]: isLastTabSelected()
        })
      }}
      TabIndicatorProps={{style: {display: 'none'}}}
      {...isVertical && {orientation: 'vertical'}}
      onChange={onChangeTab}
      value={currentTab}
    >
      {content.map(({title}, index) => {
        const tabId = `${id}-tab-${index}`;
        return <Tab
          classes={{
            wrapper: styles['tab-title']
          }}
          className={classNames(styles.tab, {
            [styles['active-tab']]: isActive(index),
            [styles['after-active-tab']]: isAfterActive(index),
            [styles['before-active-tab']]: isBeforeActive(index)
          })}
          disableRipple
          id={tabId}
          key={tabId}
          label={title}
        />
      })}
    </MaterialUITabs>
    <div className={styles['tabs-contents']}>
      {content.map(({tabContent}, index) => <div
        className={styles['tab-content']}
        hidden={!isActive(index)}
        key={`${id}-content-${index}`}
        role="tabpanel"
      >
        {renderTabContent(tabContent)}
      </div>)}
    </div>
  </div>;
};

Tabs.propTypes = {
  className: PropTypes.string,
  content: PropTypes.array.isRequired,
  defaultTab: PropTypes.number,
  id: PropTypes.string.isRequired,
  isVertical: PropTypes.bool
};

export default Tabs;
