import classNames from 'classnames';
import {navigate} from 'gatsby-link';
import PropTypes from 'prop-types';
import React from 'react';
import Mail from '@material-ui/icons/MailOutlined';
import styles from './SuccessStoriesBanner.module.scss';
import headerStyles from '../Header/Header.module.scss';
import InnerPagesUpperBanner from '../InnerPagesUpperBanner';
import {SUCCESS_STORIES_BUTTON_TEXT, SUCCESS_STORIES_HEADING} from '../../constants/success-stories';

const SuccessStoriesBanner = (props) => {
  const {
    backgroundImage, beforeNavigate, buttonId = 'success-stories_contact-button',
    buttonText = SUCCESS_STORIES_BUTTON_TEXT, className, descriptionClassName, heading = SUCCESS_STORIES_HEADING,
    subHeading = [], textClassName
  } = props;

  return <InnerPagesUpperBanner
    bg={backgroundImage}
    buttons={[
      {
        caption: buttonText,
        classes: {root: `${headerStyles['header__contact-us']} ${headerStyles['no-padding-media-query']}`},
        color: 'primary',
        id: buttonId,
        onClick: () => {
          if (typeof beforeNavigate === 'function') {
            beforeNavigate();
          }
          navigate('/contact-us');
        },
        startIconRenderer: () => <Mail />,
        variant: 'contained'
      }
    ]}
    className={classNames(styles['success-stories-banner'], className)}
    description={subHeading}
    descriptionClassName={classNames(styles['success-stories-banner-description'], descriptionClassName)}
    text={[heading]}
    textClassName={classNames(styles['success-stories-banner-text'], textClassName)}
  />
};

SuccessStoriesBanner.propTypes = {
  backgroundImage: PropTypes.string.isRequired,
  beforeNavigate: PropTypes.func,
  buttonId: PropTypes.string,
  buttonText: PropTypes.string,
  className: PropTypes.string,
  descriptionClassName: PropTypes.string,
  heading: PropTypes.string,
  subHeading: PropTypes.array.isRequired,
  textClassName: PropTypes.string
};

export default SuccessStoriesBanner;
