import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Typography from '@material-ui/core/Typography';
import styles from './BigData.module.scss';
import Advantages from '../Advantages';
import ExpandingCard from '../ExpandingCard';
import Feed from '../Feed';
import InnerPagesUpperBanner from '../InnerPagesUpperBanner';
import LeaveMessageBanner from '../LeaveMessageBanner';
import Section from '../Section';
import SuccessStoriesBanner from '../SuccessStoriesBanner';
import Tabs from '../Tabs';
import {
  BIG_DATA_EIGHTH_BLOCK_HEADING, BIG_DATA_FIFTH_BLOCK_CONTENT, BIG_DATA_FIFTH_BLOCK_HEADING,
  BIG_DATA_FOURTH_BLOCK_CONTENT, BIG_DATA_FOURTH_BLOCK_HEADING, BIG_DATA_FOURTH_BLOCK_SUBHEADING, BIG_DATA_HEADING,
  BIG_DATA_SECOND_BLOCK_TEXT, BIG_DATA_SECOND_BLOCK_CONTENT, BIG_DATA_SECOND_BLOCK_HEADING,
  BIG_DATA_SEVENTH_BLOCK_CONTENT, BIG_DATA_SIXTH_BLOCK_HEADING, BIG_DATA_SIXTH_BLOCK_SUBHEADING, BIG_DATA_SUB_HEADING,
  BIG_DATA_SUCCESS_STORIES_SUB_HEADING, BIG_DATA_THIRD_BLOCK_CONTENT, BIG_DATA_THIRD_BLOCK_HEADING,
  BIG_DATA_THIRD_BLOCK_SUBHEADING
} from '../../constants/big-data';

const BigData = () => {
  const {
    background,
    successStoriesBackground,
    expertServicesIcons,
    analyticsIcons,
    partnersIcons,
    technologiesLogos,
    plus,
    minus,
    bigDataPosts
  } = useStaticQuery(graphql`
    query {
      background: allFile(
        filter: {
          sourceInstanceName: { eq: "bigData" }
          name: { eq: "bg" }
        }
      ) {
        nodes {
          name
          publicURL
        }
      }
      successStoriesBackground: allFile(
        filter: {
          sourceInstanceName: { eq: "otherImages" }
          name: { eq: "success-stories-bg" }
        }
      ) {
        nodes {
          name
          publicURL
        }
      }
      expertServicesIcons: allFile(
        filter: {
          sourceInstanceName: { eq: "bigData" }
          relativeDirectory: { eq: "expert-services" }
        }
      ) {
        nodes {
          name
          publicURL
        }
      }
      analyticsIcons: allFile(
        filter: {
          sourceInstanceName: { eq: "bigData" }
          relativeDirectory: { eq: "analytics" }
        }
      ) {
        nodes {
          name
          publicURL
        }
      }
      partnersIcons: allFile(
        filter: {
          sourceInstanceName: { eq: "bigData" }
          relativeDirectory: { eq: "partners" }
        }
        sort: {
          fields: [name]
          order: ASC
        }
      ) {
        nodes {
          name
          publicURL
        }
      }
      technologiesLogos: allFile(
        filter: {
          sourceInstanceName: { eq: "bigData" }
          relativeDirectory: { eq: "technologies" }
        }
        sort: {
          fields: [name]
          order: ASC
        }
      ) {
        nodes {
          name
          publicURL
        }
      }
      plus: allFile(
        filter: {
          sourceInstanceName: { eq: "otherImages" }
          name: { eq: "plus" }
        }
      ){
        nodes {
          name
          publicURL
        }
      }
      minus: allFile(
        filter: {
          sourceInstanceName: { eq: "otherImages" }
          name: { eq: "minus" }
        }
      ){
        nodes {
          name
          publicURL
        }
      }
      bigDataPosts: allMarkdownRemark(
        filter: {
          fields: {
            slug: {
              in: ["/posts/building-a-modern-digital-enterprise-with-google-cloud-data-fusion", "/posts/10-reasons-why-it-outsourcing-in-ukraine-is-a-perfect-choice-for-global-businesses", "/posts/google-cloud-migration-strategies-and-opportunities-beyond-usual-cloud-efficiencies"]
            }
          }
          frontmatter: {
            template: {
              eq: "post"
            }
            draft: {
              ne: true
            }
          }
        }
      ) {
        nodes {
          fields {
            slug
            categorySlug
            tagSlugs
          }
          frontmatter {
            title
            date
            category
            description
            socialImage
            tags
          }
        }
      }
    }
  `);
  const expertServicesIconsMap = expertServicesIcons.nodes.reduce(
    (acc, icon) => ({
      ...acc,
      [icon.name]: icon.publicURL,
    }),
    {}
  );
  const analyticsIconsMap = analyticsIcons.nodes.reduce(
      (acc, icon) => ({
        ...acc,
        [icon.name]: icon.publicURL,
      }),
      {}
  );
  const postsEdges = bigDataPosts.nodes.map(node => ({node}));

  const getBusinessDomainsTabsContents = () => BIG_DATA_FIFTH_BLOCK_CONTENT.map(({tabTitle, text, title}) => ({
    title: tabTitle,
    tabContent: () => <React.Fragment>
      <Typography color="secondary" style={{marginTop: 0}} variant="h4">{title}</Typography>
      <div>{text}</div>
    </React.Fragment>
  }));

  return (
    <Section height={'auto'} backgroundColor={'#F7F8FB'}>
      <div className={styles['big-data']}>
        <InnerPagesUpperBanner
          bg={background.nodes[0].publicURL}
          className={styles['big-data-banner']}
          description={BIG_DATA_SUB_HEADING}
          descriptionClassName={styles['big-data-banner-description']}
          text={BIG_DATA_HEADING}
          textClassName={styles['big-data-banner-text']}
        />
        <div
          className={`${styles['section-container']} ${styles['expert-services']}`}
        >
          <div className={styles['container']}>
            <Typography color={'secondary'} variant={'h2'} align={'center'}>
              {BIG_DATA_SECOND_BLOCK_HEADING}
            </Typography>
            <Typography className={styles['subheading']}>
              {BIG_DATA_SECOND_BLOCK_TEXT}
            </Typography>
            <div className={styles['card-list']}>
              {BIG_DATA_SECOND_BLOCK_CONTENT.map((item, ind) => (
                  <div className={styles['expanding-card']} key={item.icon}>
                    <ExpandingCard collapser={minus.nodes[0].publicURL}
                                   expander={plus.nodes[0].publicURL}
                                   img={expertServicesIconsMap[item.icon]}
                                   smallCard>
                      {{collapsedView: (
                            <>
                              <Typography
                                  color={'secondary'}
                                  variant={'h4'}
                                  style={{
                                    width: '100%'
                                  }}
                              >
                                {item.title}
                              </Typography>
                            </>
                        ),
                        expandedView: (
                            <div className={'expanding-card-container'} style={{borderTop: '1px solid #DFE4ED'}}>
                              {item.text}
                            </div>)}}
                    </ExpandingCard>
                  </div>
              ))}
            </div>
          </div>
        </div>
        <SuccessStoriesBanner
          backgroundImage={successStoriesBackground.nodes[0].publicURL}
          subHeading={BIG_DATA_SUCCESS_STORIES_SUB_HEADING}
        />
        <div className={`${styles['solutions']} ${styles['section-container']} ${styles['white-bg']}`}>
          <div className={styles['container']}>
            <Typography variant={'h2'} align={'center'} color={'secondary'}>
              {BIG_DATA_THIRD_BLOCK_HEADING}
            </Typography>
            <Typography className={styles['subheading']}>
              {BIG_DATA_THIRD_BLOCK_SUBHEADING}
            </Typography>
            <div className={styles['card-list']}>
              {BIG_DATA_THIRD_BLOCK_CONTENT.map((item, ind) => (
                  <React.Fragment key={item.title}>
                    <div className={styles['card']}>
                      <div>
                        <img
                            src={analyticsIconsMap[item.icon]}
                            alt={item.icon}
                        />
                        <Typography>{item.title}</Typography>
                      </div>
                      <Typography>{item.text}</Typography>
                    </div>
                    <div className={styles['expanding-card']}>
                      <ExpandingCard smallCard
                                     img={analyticsIconsMap[item.icon]}>
                        {{collapsedView: (
                              <>
                                <Typography
                                    variant={'h4'}
                                    style={{
                                      fontSize: '14px',
                                      lineHeight: '24px',
                                    }}
                                >
                                  {item.title}
                                </Typography>
                              </>
                          ),
                          expandedView: (
                              <div className={'expanding-card-container'}>
                                <hr
                                    style={{
                                      background: '#DFE4ED'
                                    }}
                                />
                                {item.text}
                              </div>)}}
                      </ExpandingCard>
                    </div>
                  </React.Fragment>
              ))}
            </div>
          </div>
        </div>
        <div className={`${styles['partners']} ${styles['section-container']}`}>
          <div className={styles['container']}>
            <Typography variant={'h2'} color={'secondary'} align={'center'}>
              {BIG_DATA_FOURTH_BLOCK_HEADING}
            </Typography>
            <div className={styles['subheading']}>
              {BIG_DATA_FOURTH_BLOCK_SUBHEADING.map((line, i) => (
                <Typography variant={'h4'} align={'center'} key={line}>
                  {line}
                </Typography>
              ))}
            </div>
            <div className={styles['cards-container']}>
              {BIG_DATA_FOURTH_BLOCK_CONTENT.map(({ icon }) => (
                <div key={icon} className={styles['card']}>
                  <img
                      key={icon}
                      src={partnersIcons.nodes[icon - 1].publicURL}
                      alt="icon"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={`${styles['business-domains']} ${styles['section-container']} ${styles['white-bg']}`}>
          <div className={styles['container']}>
            <Typography align={'center'} color={'secondary'} variant={'h2'}>
              {BIG_DATA_FIFTH_BLOCK_HEADING}
            </Typography>
            <Tabs
              className={styles['business-domains-tabs']}
              content={getBusinessDomainsTabsContents()}
              id="business-domains"
              isVertical
            />
            <div className={styles['card-list']}>
              {BIG_DATA_FIFTH_BLOCK_CONTENT.map((item, ind) => (
                  <React.Fragment key={item.tabTitle}>
                    <div className={styles['expanding-card']}>
                      <ExpandingCard expandedCardHeaderClassName={styles['expanded-card-header']} smallCard>
                        {{collapsedView: (
                          <>
                            <Typography
                              className={styles['business-domains-card-title']}
                              variant={'h4'}
                            >
                              {item.tabTitle}
                            </Typography>
                          </>
                          ),
                          expandedView: (
                            <div className={'expanding-card-container'}>
                              <hr
                                style={{
                                  background: '#DFE4ED'
                                }}
                              />
                                {item.text}
                            </div>)}}
                      </ExpandingCard>
                    </div>
                  </React.Fragment>
              ))}
            </div>
          </div>
        </div>
        <div className={`${styles['technologies']} ${styles['section-container']}`}>
          <div className={styles['container']}>
            <div className={styles['technologies-sub-block']}>
              <Typography className={styles['technologies-heading']} color={'secondary'} variant={'h2'}>
                {BIG_DATA_SIXTH_BLOCK_HEADING}
              </Typography>
              <div>
                <Typography variant={'h4'}>
                  {BIG_DATA_SIXTH_BLOCK_SUBHEADING}
                </Typography>
                {/* TODO Add 'Learn more' button leading to Big Data Technologies page */}
              </div>
            </div>
            <div className={`${styles['technologies-sub-block']} ${styles['technologies-images-container']}`}>
              {technologiesLogos.nodes.map(({name, publicURL}) => <img
                alt={name}
                className={styles['technologies-image']}
                key={name}
                src={publicURL}
              />)}
            </div>
          </div>
        </div>
        <div className={`${styles['advantages']} ${styles['section-container']} ${styles['white-bg']}`}>
          <div className={styles['container']}>
            <Advantages content={BIG_DATA_SEVENTH_BLOCK_CONTENT} />
          </div>
        </div>
        <div className={`${styles['insights']} ${styles['section-container']}`}>
          <div className={styles['container']}>
            <Typography
              align={'center'}
              className={styles['insights-heading']}
              color={'secondary'}
              key={'insights-heading'}
              variant={'h2'}
            >
              {BIG_DATA_EIGHTH_BLOCK_HEADING}
            </Typography>
            <Feed edges={postsEdges} key={'insights-feed'} />
          </div>
        </div>
        <div className={`${styles['leave-message']} ${styles['section-container']}`}>
          <LeaveMessageBanner />
        </div>
      </div>
    </Section>
  );
};

export default BigData;
