import React from 'react';
import Layout from '../components/Layout';
import { useSiteMetadata } from '../hooks';
import {BIG_DATA} from '../constants/services-names';
import BigData from '../components/BigData';
import {graphql, useStaticQuery} from "gatsby";

const BigDataTemplate = ({ location }) => {
    const { subtitle: siteSubtitle } = useSiteMetadata();
    const { bg } = useStaticQuery(graphql`
        query {
            bg: allFile(filter: { sourceInstanceName: { eq: "preview" } name: { eq: "big-data" } }) {
                nodes {
                    name
                    publicURL
                }
            }
        }
    `);

    return (
        <Layout title={BIG_DATA}
                description={siteSubtitle}
                location={location}
                socialImage={bg.nodes[0].publicURL}>
            <BigData />
        </Layout>
    );
};

export default BigDataTemplate;
